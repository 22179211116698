YUI.add('interview-help', function(Y) {

        "use strict";

        // handy constants and shortcuts used in the module
        var clazz, proto, statics,
            NAME = 'HelpInterview';


        // Static members here, specially:
        statics = {
            NAME: NAME,
            ATTRS: {

            }
        };

        proto = {

            handler: [],
            config : null,

            initializer: function() {
                var instance = this;

                Y.once('smartsteuer:ready', function(config){instance.config = config}, instance);

                instance.handler.push( Y.on('*:field-focus', instance._showOnFocus, instance ) );
                instance.handler.push( Y.on('*:field-focus-disabled', instance._showOnFocus, instance ) );
            },

            destructor: function() {
                Y.Array.each(this.handler, function(h){h.detach()});
            },

            _showOnFocus: function(e) {
                if(e.fieldNode) {
                    e.currentTarget = e.fieldNode.ancestor('div');
                }
                else {
                    e.currentTarget = e.target.get('container');
                }
                this._show(e);
            },

            _appendMoreHelpLink: function (help, target, more) {
                if(more && more == 'true') {
                    help += '<div class="case-editor-interview-field-help-more-container">' +
                        '<button class="ui-app-help-open btn btn-s btn-hilited" data-field-id='
                        + target.one('.onse-field').getAttribute("data-id") +
                        '>Mehr Hilfe?</button></div>';
                }
                return help;
            },

            _show: function(e) {
                var target = e.currentTarget,
                    help = target.one('.onse-field').getAttribute('data-help'),
                    more = target.one('.onse-field').getAttribute('data-more-help'),
                    field = target.one('.onse-field').ancestor('.case-interview-field'),
                    wrapperNode = Y.one('#case-interview-field-help'),
                    contentNode = Y.one('#case-interview-field-help-inner'),
                    position = undefined;

                if(field != null) { //null on overviews, position is already ok
                    position = field.getY()- Y.one('#case-interview-form').getY();
                    wrapperNode.setStyle('marginTop',(position+'px'));
                }

                help = this._appendMoreHelpLink(help, target, more);

                contentNode.setContent(help);

                if(Y.one('#ui-is-mobile').get('value') == 'true') {

                    if(!target.hasClass('ui-case-interview-field')) {
                        target = target.ancestor('.ui-case-interview-field');
                    }

                    var helpNode = Y.Node.create(
                        '<div class="mobile-help" style="visibility: hidden;">' + help + '</div>'
                    );

                    //remove previously rendered help
                    if(Y.one('.mobile-help')) {
                        Y.one('.mobile-help').transition(
                            {easing: 'ease-out', delay: 0, duration: 0.15, height: 0},
                            function() {
                                Y.all('.mobile-help').remove(true);
                            }
                        );
                    }

                    //render help below current field instead - needs delay to reg clicks correctly in chrome
                    //and it has to finish after animations
                    Y.later(300, this, function() {
                        target.append(helpNode);

                        var height = Y.one('.mobile-help').getComputedStyle('height');

                        Y.one('.mobile-help').setStyle('height', '0px');
                        Y.one('.mobile-help').setStyle('visibility', '');

                        Y.one('.mobile-help').transition(
                            {easing: 'ease-in', delay: 0, duration: 0.15, height: height},
                            function() {}
                        );
                    });
                }
            },

        };

        /**
         *
         * @class InterviewHelp
         * @extends Base
         * @constructor
         * @cfg {object} configuration attributes
         */
        clazz = Y.Base.create(NAME, Y.Base, [], proto, statics );

        Y[NAME] = clazz;


    }, '1.0.0', {
        requires:[
            'oop'
        ]
    }
);

